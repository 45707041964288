export { default as dracula } from "./dracula"
export { default as duotoneDark } from "./duotoneDark"
export { default as duotoneLight } from "./duotoneLight"
export { default as github } from "./github"
export { default as nightOwl } from "./nightOwl"
export { default as nightOwlLight } from "./nightOwlLight"
export { default as oceanicNext } from "./oceanicNext"
export { default as okaidia } from "./okaidia"
export { default as palenight } from "./palenight"
export { default as shadesOfPurple } from "./shadesOfPurple"
export { default as synthwave84 } from "./synthwave84"
export { default as ultramin } from "./ultramin"
export { default as vsDark } from "./vsDark"
export { default as vsLight } from "./vsLight"
export { default as jettwaveDark } from "./jettwaveDark"
export { default as jettwaveLight } from "./jettwaveLight"
export { default as oneDark } from "./oneDark"
export { default as oneLight } from "./oneLight"
export { default as gruvboxMaterialDark } from "./gruvboxMaterialDark"
export { default as gruvboxMaterialLight } from "./gruvboxMaterialLight"
